<template>
    <div
      v-if="
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
          $store.state.fastPermissoes.ativo == 'S' &&
          $store.state.fastPlataformaModulos.modulo_secretaria
      "
    >
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
    
            <ul class="fd-app-breadcrumb">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                >Modo tesouraria</a>              
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Relatórios - Posição Contrato Alunos</a>
              </li>
            </ul>
          </nav>
  
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-adm-content mt-5 container">
          
          <!-- Conteúdo Principal Relatório -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRelatorio">

              <section class="fd-app-welcome text-left">
                  <h2><b>Relatório Posição Contrato Alunos</b></h2>
                  <img
                    class="mb-3"
                    :src="require('@/assets/images/form-effect-01.png')"
                    style="max-height: 16px"
                  >
              </section>

              <div class="d-flex">
                <div class="align-self-start mr-auto mb-2">
                  <button v-if="this.fastAlunoPosRel.filtroEscolhido.idInvoice"
                    @click="fastAlunoPosRel.filtroEscolhido.idInvoice = '', getAlunoPosRel()" type="button"
                    class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                    Invoice: {{ this.fastAlunoPosRel.filtroEscolhido.idInvoice }} <b-icon icon="x-lg" font-scale="0.7"
                      class="mb-1"></b-icon>
                  </button>
                  <button v-if="this.fastAlunoPosRel.filtroEscolhido.idMatriculador "
                    @click="fastAlunoPosRel.filtroEscolhido.idMatriculador = '', fastAlunoPosRel.filtros.usuarioGerou = '', getAlunoPosRel()" type="button"
                    class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                    Matriculado por: {{ this.fastAlunoPosRel.filtros.usuarioGerou }} <b-icon icon="x-lg" font-scale="0.7"
                      class="mb-1"></b-icon>
                  </button>
                  <button v-if="this.fastAlunoPosRel.filtroEscolhido.nomeAluno"
                    @click="fastAlunoPosRel.filtroEscolhido.nomeAluno = '', getAlunoPosRel()" type="button"
                    class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                    Aluno: {{ this.fastAlunoPosRel.filtroEscolhido.nomeAluno }} <b-icon icon="x-lg" font-scale="0.7"
                      class="mb-1"></b-icon>
                  </button>
                  <button v-if="this.fastAlunoPosRel.filtroEscolhido.nomeResponsavel"
                    @click="fastAlunoPosRel.filtroEscolhido.nomeResponsavel = '', getAlunoPosRel()" type="button"
                    class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                    Resp.: {{ this.fastAlunoPosRel.filtroEscolhido.nomeResponsavel }} <b-icon icon="x-lg"
                      font-scale="0.7" class="mb-1"></b-icon>
                  </button>
                  <button v-if="this.fastAlunoPosRel.filtroEscolhido.idMatricula"
                    @click="fastAlunoPosRel.filtroEscolhido.idMatricula = '', getAlunoPosRel()" type="button"
                    class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                    Matrícula.: {{ this.fastAlunoPosRel.filtroEscolhido.idMatricula }} <b-icon icon="x-lg"
                      font-scale="0.7" class="mb-1"></b-icon>
                  </button>
                </div>
              </div>

              <section>
                <div class="row">
                <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-3">
                  <label class="text-secondary mb-0 mt-2"><small>Plataforma:</small></label>
                  <select
                    class="form-control form-control-sm"
                    v-model="fastAlunoPosRel.filtroEscolhido.idPlataforma"
                    >
                    <option>Todas</option>
                    <option v-for="(plataforma, index) in fastAlunoPosRel.filtros.plataformas" :key="index"
                      :value="plataforma.id_plataforma">
                      {{ plataforma.nome_plataforma }}
                    </option>
                  </select>
                  <label class="text-secondary mb-0 mt-2"><small>Invoice:</small></label>
                  <input
                  type="text"
                  v-model="fastAlunoPosRel.filtroEscolhido.idInvoice"
                  class="form-control form-control-sm"
                  />
                  <label class="text-secondary mb-0 mt-2"><small>Matrícula Ativa:</small></label>
                  <div class="row pl-3 pr-3">
                    <b-form-checkbox v-model="fastAlunoPosRel.filtroEscolhido.matriculaAtiva" value="">Todos</b-form-checkbox>
                    <b-form-checkbox class="ml-3" v-model="fastAlunoPosRel.filtroEscolhido.matriculaAtiva" value="true">Sim</b-form-checkbox>
                    <b-form-checkbox class="ml-3" v-model="fastAlunoPosRel.filtroEscolhido.matriculaAtiva" value="false">Não</b-form-checkbox>
                  </div>
                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-4">
                  <label class="text-secondary mb-0 mt-2"><small>Aluno:</small></label>
                  <input
                  type="text"
                  v-model="fastAlunoPosRel.filtroEscolhido.nomeAluno"
                  class="form-control form-control-sm"
                  />
                  <label class="text-secondary mb-0 mt-2"><small>Responsável:</small></label>
                  <input
                  type="text"
                  v-model="fastAlunoPosRel.filtroEscolhido.nomeResponsavel"
                  class="form-control form-control-sm"
                  />
                  <label class="text-secondary mb-0 mt-2"><small>Ano Matrícula:</small></label>
                  <b-form-select 
                  size="sm"
                  v-model="fastAlunoPosRel.filtroEscolhido.anoMatricula"
                  @change="fastAlunoPosRel.filtroEscolhido.dataMatriculaStart = '', fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd = ''">
                    <option value="">Todos</option>
                    <option v-for="ano in fastAlunoPosRel.filtros.anos" :value="ano">{{ ano }}</option>
                  </b-form-select>

                </form>
                <form class="form col-sm-12 col-md-12 col-lg-4 pl-3 pr-3">
                  <label class="text-primary mb-1 mt-2 row">
                    <small class="text-primary ml-0 pl-3 col-8">Data Matrícula:</small>
                    <small
                    class="col-4 text-right mr-0 pr-3"
                    role="button"
                    v-if="fastAlunoPosRel.filtroEscolhido.dataMatriculaStart || fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd" 
                    @click="fastAlunoPosRel.filtroEscolhido.dataMatriculaStart = '', fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd = ''"
                    >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                  </label>
                  <div class="row pl-3 pr-3">
                    <b-form-datepicker 
                    :class="validaDataFiltro(fastAlunoPosRel.filtroEscolhido.dataMatriculaStart, fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                    @input="fastAlunoPosRel.filtroEscolhido.anoMatricula = '', validaDataFiltro(fastAlunoPosRel.filtroEscolhido.dataMatriculaStart, fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd, true)"
                    v-model="fastAlunoPosRel.filtroEscolhido.dataMatriculaStart"
                    locale="pt-BR"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                    label-no-date-selected="Selecionar data inicial"
                    size="sm"
                    placeholder="Selecionar data inicial"
                    label-help="Use o cursor para selecionar"
                    ></b-form-datepicker>
                    <b-form-datepicker 
                    :class="validaDataFiltro(fastAlunoPosRel.filtroEscolhido.dataMatriculaStart, fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                    @input="fastAlunoPosRel.filtroEscolhido.anoMatricula = '',validaDataFiltro(fastAlunoPosRel.filtroEscolhido.dataMatriculaStart, fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd, true)"
                    v-model="fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd"
                    locale="pt-BR"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                    label-no-date-selected="Selecionar data final"
                    size="sm"
                    placeholder="Selecionar data final"
                    label-help="Use o cursor para selecionar"
                    ></b-form-datepicker>
                  </div>
                  <label class="text-secondary mb-0 mt-2"><small>Matriculador:</small></label>
                  <div style="position: relative">
                    <input 
                      :class="fastAlunoPosRel.filtros.usuarioGerou.length ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                      v-model="fastAlunoPosRel.filtros.usuarioGerou" 
                      type="text" 
                      :list="fastAlunoPosRel.filtros.datalistId"
                      @input="filtraPessoaSugestao('usuarioGerou', 'usuariosGerou')"
                      @keyup.esc="selecionaPessoaSugerida('')"
                    >
                    <ul v-if="fastAlunoPosRel.filtros.usuariosGerou.length" class="sugestao-lista">
                      <li 
                      class="sugestao-item"
                      v-for="usuario in fastAlunoPosRel.filtros.usuariosGerou" 
                      :key="usuario.id_usuario" 
                      @click="selecionaPessoaSugerida(usuario)">
                        {{ usuario.nome_razao }}
                      </li>
                    </ul>
                  </div>
                  <label class="text-secondary mb-0 mt-2"><small>Matrícula:</small></label>
                  <input
                  type="text"
                  v-model="fastAlunoPosRel.filtroEscolhido.idMatricula"
                  class="form-control form-control-sm"
                  />
                </form>
              </div>
                <div class="row">
                  <div class="text-left col-6 mt-4 mb-3">
                    <b-button @click="exportaPlanilha()" variant="custom-outline-primary btn-sm text-capitalize rounded">Exportar</b-button>                       
                  </div>
                  <div class="text-right col-6 mt-4 mb-3">
                    <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                    <b-button @click="getAlunoPosRel()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                  </div>
                </div>
              </section>

              <div class="row">
                <div class="col-12 table-responsive">
                  <!--tabela oculta sem paginação para exportar-->
                  <table id="relatorio" class="table d-none">
                    <thead>
                      <tr>
                        <th>
                          <small>Ano Mat</small>
                        </th>
                        <th>
                          <small>Turma</small>
                        </th>
                        <th>
                          <small>Mat./Invoice</small>
                        </th>
                        <th>
                          <small>Matriculador</small>
                        </th>
                        <th>
                          <small>Valor Contrato</small>
                        </th>
                        <th>
                          <small>Valor Pago</small>
                        </th>
                        <th>
                          <small>Valor Saldo</small>
                        </th>
                        <th>
                          <small>Meses Permanência</small>
                        </th>
                        <th>
                          <small>Status</small>
                        </th>
                        <th>
                          <small>Aluno</small>
                        </th>
                        <th>
                          <small>Telefone</small>
                        </th>
                      </tr>
                    </thead>
                <tbody>
                  <tr
                    v-for="(e, index) in fastAlunoPosRel.filtro"
                    :key="index"
                  >
                    <td>
                      <small>{{ e.ano_matricula }}</small>                                  
                    </td>
                    <td>
                      <small>{{ e.sigla_turma }}</small>                                  
                    </td>
                    <td>
                      <small>{{ e.id_matricula }} / -</small>                                  
                    </td>
                    <td>
                      <small>{{ e.Matriculadopor }}</small>                                  
                    </td>
                    <td>
                      <small>{{ formataDataT(e.data_matricula) }}</small>                                  
                    </td>
                    <td>
                      <small>R$ {{ formataPreco(e.ValorContrato) }}</small>                                  
                    </td>
                    <td>
                      <small v-if="e.ValorPago">R$ {{ formataPreco(e.ValorPago) }}</small>
                      <small v-else>R$ 0,00</small>                                  
                    </td>
                    <td>
                      <small>R$ {{ formataPreco((e.ValorContrato - e.ValorPago)) }}</small>                                  
                    </td>
                    <td>
                      <small v-if="e.MesesPermanencia === 0">{{ e.MesesPermanencia }}</small>
                      <small v-else>-</small>                               
                    </td>
                    <td>
                      <span v-if="!e.MatriculaAtiva && !e.InvoiceCancelada" class="text-primary"><small>Pendente</small> </span>
                      <span v-else-if="!e.MatriculaAtiva && e.InvoiceCancelada" class="text-secondary"><small>Cancelada</small> </span>
                      <span v-else-if="e.MatriculaAtiva && !e.InvoiceCancelada" class="text-success"><small>Ativa</small> </span>
                      <span v-else-if="e.MatriculaAtiva && e.InvoiceCancelada" class="text-secondary"><small>Cancelada</small> </span>
                    </td>
                    <td>
                      <small>{{ e.nome_aluno }}</small>                                  
                    </td>
                    <td>
                      <small>{{ e.telefone }}</small>                                  
                    </td>
                  </tr>
                </tbody>
                  </table>
                  <table class="table table-sm text-nowrap table-hover">
                    <thead class="thead-dark" v-if="fastAlunoPosRel.loading">
                      <tr>
                        <th
                          colspan="12"
                        >
                        <b-icon icon="gear-fill" animation="spin"/> Carregando 
                        </th>
                      </tr>
                    </thead>
                    <thead class="thead-dark" v-if="!fastAlunoPosRel.loading">
                      <tr>
                        <th class="pl-2 pt-2">
                          <b-form-checkbox v-model="fastAlunoPosRel.selectAll"/>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.ano_matricula !== 0 }" @click="orderByColumn('ano_matricula')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Ano Mat.&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.ano_matricula === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.ano_matricula === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.turma !== 0 }" @click="orderByColumn('turma')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Turma&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.turma === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.turma === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.mat_invoice !== 0 }" @click="orderByColumn('mat_invoice')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Mat./Invoice&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.mat_invoice === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.mat_invoice === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.matriculador !== 0 }" @click="orderByColumn('matriculador')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Matriculador&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.matriculador === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.matriculador === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.inicio_estudo !== 0 }" @click="orderByColumn('inicio_estudo')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Início Estudo&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.inicio_estudo === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.inicio_estudo === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.valor_contrato !== 0 }" @click="orderByColumn('valor_contrato')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Valor Contrato&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.valor_contrato === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.valor_contrato === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.valor_pago !== 0 }" @click="orderByColumn('valor_pago')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Valor Pago&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.valor_pago === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.valor_pago === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.valor_saldo !== 0 }" @click="orderByColumn('valor_saldo')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Valor Saldo&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.valor_saldo === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.valor_saldo === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.meses_perman !== 0 }" @click="orderByColumn('meses_perman')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Perman.&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.meses_perman === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.meses_perman === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.status !== 0 }" @click="orderByColumn('status')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                            Status&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.status === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.status === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small :class="{ active: this.fastAlunoPosRel.orderBy.aluno !== 0 }" @click="orderByColumn('aluno')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                            Aluno&nbsp;
                            <div class="d-flex flex-column">
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.aluno === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                              <b-icon v-if="this.fastAlunoPosRel.orderBy.aluno === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                              <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                            </div>
                          </small>
                        </th>
                        <th>
                          <small role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                            Telefone
                          </small>
                        </th>
                        <th>
                          <small class="font-weight-bold d-flex pb-1 justify-content-center">
                            Ações
                            <div class="d-flex flex-column"></div>
                          </small>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="fastAlunoPosRel.loading">
                  <tr>
                    <td
                      colspan="12"
                      
                    >
                    <b-icon icon="gear-fill" animation="spin"/> Carregando 
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="fastAlunoPosRel.paginacao.length">
                  <tr
                    v-for="(e, index) in fastAlunoPosRel.paginacao"
                    :key="index"
                  >
                    <td class="pl-3 pt-2"
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                    >
                      <b-form-checkbox role="button" type="checkbox" v-model="fastAlunoPosRel.selectedItems" :value="e.id_matricula + '-' + e.id_plataforma"/>
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ e.ano_matricula }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ e.sigla_turma }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ e.id_matricula }} / {{ e.id_invoice }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ e.Matriculadopor }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}" 
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ formataDataT(e.data_iniciou_estudos) }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>R$ {{ formataPreco(e.ValorContrato) }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small v-if="e.ValorPago">R$ {{ formataPreco(e.ValorPago) }}</small>
                      <small v-else>R$ 0,00</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>R$ {{ formataPreco((e.ValorContrato - e.ValorPago)) }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small v-if="e.MesesPermanencia === 0">{{ e.MesesPermanencia }}</small>
                      <small v-else>-</small>                               
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <span v-if="!e.MatriculaAtiva && !e.InvoiceCancelada" class="text-primary"><small>Pendente</small> </span>
                      <span v-else-if="!e.MatriculaAtiva && e.InvoiceCancelada" class="text-secondary"><small>Cancelada</small> </span>
                      <span v-else-if="e.MatriculaAtiva && !e.InvoiceCancelada" class="text-success"><small>Ativa</small> </span>
                      <span v-else-if="e.MatriculaAtiva && e.InvoiceCancelada" class="text-secondary"><small>Cancelada</small> </span>
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ e.nome_aluno }}</small>                                  
                    </td>
                    <td
                      v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      role="button"
                      @click="exibeDetalhes(e)"
                    >
                      <small>{{ e.telefone }}</small>                                  
                    </td>
                    <td class="text-center">
                      <b-dropdown size="sm" variant="light rounded">
                        <b-dropdown-item @click.prevent="exibeDetalhes(e)">Detalhes</b-dropdown-item>
                        <b-dropdown-item disabled @click.prevent="exibeDetalhes(e)">Renegociação</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="12"
                    >
                      Nenhuma parcela encontrada
                    </td>
                  </tr>
                </tbody>
                  </table>
                </div>
                <div class="col-12 text-right mt-4">
                    <div class="d-inline">
                      <h6 class="font-weight-bold d-inline">Valor Total Contratos: </h6>
                      <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalContratosSelected) }}</b-badge></h5>
                    </div>
                    <div class="mt-2 mb-2">
                      <h6 class="font-weight-bold d-inline">Valor Total Pago: </h6>
                      <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalPagoSelected) }}</b-badge></h5>
                    </div>
                </div>
                <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="20"
                :items="fastAlunoPosRel.filtro"
                @changePage="fastAlunoPosRel.paginacao = $event"
              />
            </div>
              </div>

            </div>
          </transition>
          <!-- /Conteúdo Principal Relatório -->

          <!-- Detalhes - Ações -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirDetalhes">
              <DetalhesRecebivel 
                :id_plataforma="fastInvoiceFiltrosDetalhes.idPlataforma" 
                :id_invoice="fastInvoiceFiltrosDetalhes.idInvoice" 
                :parcela="fastInvoiceFiltrosDetalhes.parcela"
                tipo="detalhes"
              />
            </div>
          </transition>
          <!-- /Detalhes - Ações -->

          <div class="mt-5 mb-5">
            <a
              v-if="fastNavegacao.exibirRelatorio" 
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
            <a
              v-if="fastNavegacao.exibirDetalhes"
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/tesouraria' 
              "
              @click.prevent="alteraFastNavegacao('exibirRelatorio')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>

        </div>

        <!--modals-->
        <modal
          name="modalVerLog"
          :scrollable="true"
          height="auto"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Log / Motivo</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVerLog')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12 mt-4 mb-4">

                  <p class="text-info">
                    {{ fastAlunoPosRel.logRecebivel }}
                  </p>

                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalVerLog'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>

      </div>
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  import Pagination from "../components/Pagination";
  import DetalhesRecebivel from "../components/DetalhesRecebivel.vue"
  import * as XLSX from 'xlsx';
  
  export default {
    name: "HomeInternoTesourariaRelatoriosAlunoPosicao",
    components: {
      Pagination,
      DetalhesRecebivel
    },
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        
        fastLinkClipboard: false,
        fastNavegacao: {
          exibirRelatorio : true,
          exibirDetalhes : false,
        },
        fastAlunoPosRel: {
          loading: true,
          filtro: [],
          paginacao: [],
          filtroEscolhido : {
            idPlataforma: this.$route.params.id_plataforma,
            nomeAluno: '',
            nomeResponsavel: '',
            idInvoice: 0,
            idMatricula: 0,
            anoMatricula: '',
            idMatriculador: 0,
            dataMatriculaStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            dataMatriculaEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
            matriculaAtiva: '',
          },
          filtros : {
            plataformas: [],
            status: '',
            usuariosGerou : [],
            usuariosCancelou : [],
            usuarioGerou: '',
            usuarioCancelou: '',
            anos: [
              new Date().getFullYear() - 3,
              new Date().getFullYear() - 2,
              new Date().getFullYear() - 1,
              new Date().getFullYear(),
              new Date().getFullYear() + 1,
              new Date().getFullYear() + 2,
              new Date().getFullYear() + 3
            ]
          },
          logRecebivel: '',
          gatewaysPagamento: [],
          orderBy: {
            ano_matricula: 0,
            turma: 0,
            mat_invoice: 0,
            matriculador: 0,
            inicio_estudo: 0,
            valor_contrato: 0,
            valor_pago: 0, 
            valor_saldo: 0,
            meses_perman: 0,
            status: 0, 
            aluno: 0,
          },
          selectedItems: [],
          selectAll: false
        },
        fastInvoiceFiltrosDetalhes: {
          idPlataforma : '',
          idInvoice : '',
          parcela : '',
        },
        tableData : [],
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
          .then(() => {
            if (
              this.$store.state.fastPlataformaModulos.modulo_secretaria
            ) {            
              this.$store.state.fastCarregando = false;
              this.getFastHoraServidor()
              .then(() => {
                this.getPlataformasFilhas()
                this.getAlunoPosRel(this.$route.params.id_plataforma)
              })
  
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Módulo não disponível para sua plataforma", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    computed: {
      totalContratosSelected() {
        return this.fastAlunoPosRel.filtro.reduce((total, item) => {
          if (!this.fastAlunoPosRel.selectedItems.length){
            return total + item.ValorContrato
          }
          if (this.fastAlunoPosRel.selectedItems.includes(item.id_matricula + "-" + item.id_plataforma)) {
            return total + item.ValorContrato
          } else {
            return total
          }
        }, 0)
      },
      totalPagoSelected() {
        return this.fastAlunoPosRel.filtro.reduce((total, item) => {
          if (!this.fastAlunoPosRel.selectedItems.length){
            return total + item.ValorPago
          }
          if (this.fastAlunoPosRel.selectedItems.includes(item.id_matricula + "-" + item.id_plataforma)) {
            return total + item.ValorPago
          } else {
            return total
          }
        }, 0)
      }
    },
    watch: {
      'fastAlunoPosRel.selectAll'(val) {
        if (val) {
          this.fastAlunoPosRel.selectedItems = this.fastAlunoPosRel.filtro.map((item) => item.id_matricula + "-" + item.id_plataforma)
        } else {
          this.fastAlunoPosRel.selectedItems = []
        }
      }
    },
    methods: {
      getPlataformasFilhas(){
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi("api/fast_plataforma/lista_filhas_novo", `id_plataforma=${this.fastAlunoPosRel.filtroEscolhido.idPlataforma}`)
      .then((res) => {          
        if (res.Data.length) {
          this.fastAlunoPosRel.filtros.plataformas = res.Data;
        } else{
          this.fastAlunoPosRel.filtros.plataformas = this.$route.params.id_plataforma;
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      async getAlunoPosRel(){       
        this.fastAlunoPosRel.loading = true

        let plataforma;
        let todasPlataformasFilhas;

        todasPlataformasFilhas = this.fastAlunoPosRel.filtroEscolhido.idPlataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastAlunoPosRel.filtroEscolhido.idPlataforma;

        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_aluno_posicao", `IdPlataforma=${plataforma}&AnoMatricula=${this.fastAlunoPosRel.filtroEscolhido.anoMatricula}&NomeAluno=${this.fastAlunoPosRel.filtroEscolhido.nomeAluno}&NomeResponsavel=${this.fastAlunoPosRel.filtroEscolhido.nomeResponsavel}&IdInvoice=${this.fastAlunoPosRel.filtroEscolhido.idInvoice}&MatriculaAluno=${this.fastAlunoPosRel.filtroEscolhido.idMatricula}&DataMatriculaStart=${this.fastAlunoPosRel.filtroEscolhido.dataMatriculaStart}&DataMatriculaEnd=${this.fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd}&MatriculaAtiva=${this.fastAlunoPosRel.filtroEscolhido.matriculaAtiva}&IdMatriculador=${this.fastAlunoPosRel.filtroEscolhido.idMatriculador}`)

        .then(res => {
          console.log("getAlunoPosRel", res)
          if (res.length) {
            this.fastAlunoPosRel.filtro = todasPlataformasFilhas ? res : res.filter(item => item.id_plataforma == this.fastAlunoPosRel.filtroEscolhido.idPlataforma);
          }
          else {
            this.fastAlunoPosRel.filtro = []
          }
  
          this.fastAlunoPosRel.loading = false
        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastAlunoPosRel.loading = false
        })
      },
      alteraFiltroStatus(){
        if(this.fastAlunoPosRel.filtros.status == 'P'){
          this.fastAlunoPosRel.filtroEscolhido.statusCancelado = '';
          this.fastAlunoPosRel.filtroEscolhido.statusVencido = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPendente = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPago = true;
        }
        if(this.fastAlunoPosRel.filtros.status == 'A'){
          this.fastAlunoPosRel.filtroEscolhido.statusCancelado = '';
          this.fastAlunoPosRel.filtroEscolhido.statusVencido = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPago = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPendente = true;
        }
        if(this.fastAlunoPosRel.filtros.status == 'V'){
          this.fastAlunoPosRel.filtroEscolhido.statusCancelado = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPendente = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPago = '';
          this.fastAlunoPosRel.filtroEscolhido.statusVencido = true;
        }
        if(this.fastAlunoPosRel.filtros.status == 'C'){
          this.fastAlunoPosRel.filtroEscolhido.statusVencido = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPendente = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPago = '';
          this.fastAlunoPosRel.filtroEscolhido.statusCancelado = true;
        }
        if(!this.fastAlunoPosRel.filtros.status){
          this.fastAlunoPosRel.filtroEscolhido.statusVencido = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPendente = '';
          this.fastAlunoPosRel.filtroEscolhido.statusPago = '';
          this.fastAlunoPosRel.filtroEscolhido.statusCancelado = '';
        }
      },
      filtraPessoaSugestao(filtro, listagem){
      if (this.fastAlunoPosRel.filtros[filtro].length >= 3) {
        setTimeout(() => {
          this.promiseGetFastApi("api/fast_pessoa/lista_paginado", `PageIndex=1&PageSize=100&id_plataforma=${this.fastAlunoPosRel.filtroEscolhido.idPlataforma}&nome_pessoa=${this.fastAlunoPosRel.filtros[filtro]}&matriculador=true`)
          .then((res) => {          
            if (res.Data.length) {
              this.fastAlunoPosRel.filtros[listagem] = res.Data;
              }
            })
            .catch((e) => {
                console.log(e);
            });
          }, 1000)
          } else {
            this.fastAlunoPosRel.filtros.usuariosGerou = []
        }
      },
      selecionaPessoaSugerida(usuario) {
          this.fastAlunoPosRel.filtros.usuariosGerou = [];
          if(usuario == ''){
            this.fastAlunoPosRel.filtroEscolhido.idUsuarioGerou = '';
            this.fastAlunoPosRel.filtros.usuarioGerou = '';
          }else{
            this.fastAlunoPosRel.filtros.usuarioGerou = usuario.nome_razao;
            this.fastAlunoPosRel.filtroEscolhido.idMatriculador = usuario.id_usuario;
          }
      },
      limpaFiltros(){
        this.fastAlunoPosRel.filtroEscolhido.idPlataforma = this.$route.params.id_plataforma
        this.fastAlunoPosRel.filtroEscolhido.dataMatriculaStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10)
        this.fastAlunoPosRel.filtroEscolhido.dataMatriculaEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
        this.fastAlunoPosRel.filtroEscolhido.nomeAluno = ''
        this.fastAlunoPosRel.filtroEscolhido.nomeResponsavel = ''
        this.fastAlunoPosRel.filtroEscolhido.idInvoice = ''
        this.fastAlunoPosRel.filtroEscolhido.idMatriculador = ''
        this.fastAlunoPosRel.filtroEscolhido.idMatricula = ''
        this.fastAlunoPosRel.filtroEscolhido.matriculaAtiva = ''
        this.fastAlunoPosRel.filtroEscolhido.anoMatricula = ''
        this.fastAlunoPosRel.filtros.status = ''
        this.fastAlunoPosRel.filtros.usuarioGerou = ''
        this.fastAlunoPosRel.filtros.usuariosGerou = []

        this.getAlunoPosRel();
      },
      calculaDiasAtraso(dataVenc){
        const dataAtual = new Date()
        const dataCalc = new Date(dataVenc)

        const difMs = dataAtual - dataCalc
        const difDias = difMs / 86400000 - 1

        return difDias.toFixed();
      },
      exportaPlanilha(){
        const table = document.querySelector('#relatorio');
        const tableRows = table.querySelectorAll('tr');
        const tableData = [];

        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].querySelectorAll('td');
          const rowData = [];

          for (let j = 0; j < tableCells.length; j++) {
            rowData.push(tableCells[j].innerText);
          }

          tableData.push(rowData);
        }

        tableData[0] = ['Ano Matrícula', 'Turma', 'Matrícula/Invoice', 'Matriculador', 'Início Estudo','Valor Contrato', 'Valor Pago', 'Valor Saldo', 'Meses Permanência', 'Status', 'Aluno', 'Telefone'];

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'FastEAD - relatorio-posicao-contrato-alunos.xlsx');
      },
      exibeModalVerLog(e){
        this.fastAlunoPosRel.logRecebivel = e
        this.showModal('modalVerLog')
      },
      exibeDetalhes(e){
        this.fastInvoiceFiltrosDetalhes.idPlataforma = Number(this.fastAlunoPosRel.filtroEscolhido.idPlataforma)
        this.fastInvoiceFiltrosDetalhes.idInvoice = e.id_invoice
        this.fastInvoiceFiltrosDetalhes.parcela = 1

        this.alteraFastNavegacao('exibirDetalhes')
      },
      copyURLBoleto(link) {
      const el = document.createElement('textarea');  
      el.value = link;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      },
      orderByColumn(coluna) {
        Object.keys(this.fastAlunoPosRel.orderBy).forEach(key => {
          if (key !== coluna) {
            this.fastAlunoPosRel.orderBy[key] = 0;
          }
        });
        // Atualiza a ordem de classificação para a coluna clicada
        this.fastAlunoPosRel.orderBy[coluna] = this.fastAlunoPosRel.orderBy[coluna] === 1 ? -1 : 1;

        // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
        this.fastAlunoPosRel.filtro.sort((a, b) => {
          const order = this.fastAlunoPosRel.orderBy[coluna];

          if (coluna === 'ano_matricula') {
            return (a.ano_matricula > b.ano_matricula ? 1 : -1) * order; 
          } else if (coluna === 'turma') {
            return a.sigla_turma.localeCompare(b.sigla_turma) * order;
          } else if (coluna === 'mat_invoice') {
            return (a.id_matricula > b.id_matricula ? 1 : -1) * order;
          } else if (coluna === 'matriculador') {
            return a.Matriculadopor.localeCompare(b.Matriculadopor) * order;
          } else if (coluna === 'inicio_estudo') {
            return (a.data_matricula > b.data_matricula ? 1 : -1) * order;
          } else if (coluna === 'valor_contrato') {
            return (a.ValorContrato > b.ValorContrato ? 1 : -1) * order;
          } else if (coluna === 'valor_pago') {
            return (a.ValorPago > b.ValorPago ? 1 : -1) * order;
          } else if (coluna === 'valor_saldo') {
            return ((a.ValorContrato - a.ValorPago) > (b.ValorContrato - b.ValorPago) ? 1 : -1) * order;
          } else if (coluna === 'meses_perman') {
            return (a.MesesPermanencia > b.MesesPermanencia ? 1 : -1) * order;
          } else if (coluna === 'status') {
            if (!a.MatriculaAtiva && !a.InvoiceCancelada) {
              if (!b.MatriculaAtiva && !b.InvoiceCancelada) return 0;
              return -1 * order;
            }
            if (!a.MatriculaAtiva && a.InvoiceCancelada) {
              if (!b.MatriculaAtiva && b.InvoiceCancelada) return 0;
              if (!b.MatriculaAtiva && !b.InvoiceCancelada) return 1 * order;
              return -1 * order;
            }
            if (a.MatriculaAtiva && !a.InvoiceCancelada) {
              if (!b.MatriculaAtiva && !b.InvoiceCancelada) return 1 * order;
              if (!b.MatriculaAtiva && b.InvoiceCancelada) return -1 * order;
              return 0;
            }
            if (a.MatriculaAtiva && a.InvoiceCancelada) {
              if (!b.MatriculaAtiva && !b.InvoiceCancelada) return 1 * order;
              if (!b.MatriculaAtiva && b.InvoiceCancelada) return -1 * order;
              return 0;
            }
          } else if (coluna === 'aluno') {
              return a.nome_aluno.localeCompare(b.nome_aluno) * order;
          } else {
              return 0;
          }
        });
      },
      validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
      },
      criaTooltip(e){
        let valor_pago_tooltip = e.ValorPago
        if(!valor_pago_tooltip) valor_pago_tooltip = 0

        return `<div class="text-left">
                  Aluno: ${e.nome_aluno}<br>
                  Telef.: ${e.telefone}<br>
                  Turma: ${e.sigla_turma}<br>
                  Valor Contrato: R$ ${this.formataPreco(e.ValorContrato)}<br>
                  Valor Pago: R$ ${this.formataPreco(valor_pago_tooltip)}<br>
                  Valor Saldo: R$ ${this.formataPreco(e.ValorContrato - valor_pago_tooltip)}
                </div>`;
      }
    },
  };
  </script>
  
  <style scope>
  .container{
    max-width: 1280px;
  }
  .sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .date-picker-filtro  {
    text-align: center;
  }
  .btn-custom-primary{
    background-color: #007bff!important;
    color: #fff!important;
  }
  .btn-custom-outline-primary{
    background-color: #fff!important;
    color: #007bff!important;
    border:1px solid #007bff!important;
  }
  table th, .table th, table td, .table td{
    text-align: center;
    margin: 0 auto;
  }

  </style>
  